<template>
  <el-container>
    <el-main>
      <el-card>
        <el-divider content-position="left">基础信息</el-divider>
        <el-form :model="form" :inline="false" label-position="right" label-suffix="：" label-width="140px" class="editForm">
          <el-form-item prop="Mobile" label="手机号">{{ form.Mobile }}</el-form-item>
          <el-form-item prop="VersionName" label="订购版本">
            {{ form.VersionName || (form.VersionID == 10 ? "旗舰版" : "专业版") }}
          </el-form-item>
          <el-form-item prop="ExpireDate" label="到期时间">
            <span :style="{ color: form.LeaveDay < 15 ? 'red' : '', 'font-size': '20px' }">{{ form.ExpireDate }}</span>
            <el-button style="margin-left: 10px;" type="primary" @click="$refs.renewalRef.open(form);">续费</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card>
        <el-divider content-position="left">信息修改</el-divider>
        <el-form ref="editForm" :model="form" :rules="rules" :inline="false" label-position="right" label-width="140px" class="editForm">
          <el-form-item prop="CompanyName" label="公司名称">
            <el-input v-model="form.CompanyName"></el-input>
          </el-form-item>
          <el-form-item prop="CompanyLawer" label="公司法人">
            <el-input v-model="form.CompanyLawer"></el-input>
          </el-form-item>
          <el-form-item prop="TaxNumber" label="企业税号">
            <el-input v-model="form.TaxNumber"></el-input>
          </el-form-item>
          <el-form-item prop="CompanyAddress" label="公司地址">
            <el-input v-model="form.CompanyAddress"></el-input>
          </el-form-item>
          <div style="text-align: center;">
            <el-button type="primary" @click="save">保存</el-button>
          </div>
        </el-form>
      </el-card>
      <renewal ref="renewalRef" />
    </el-main>
  </el-container>
</template>
<script>
import { submit } from "@/api/base";
import Renewal from '@/views/components/layout/renewal.vue';
import dayjs from "dayjs";

export default {
  name: "CompanyEdit",
  components: {
    Renewal
  },
  data() {
    return {
      visible: true,
      type: "",
      title: "",
      form: {},
      rules: {
        CompanyName: [{ required: true, trigger: "blur", message: "不可为空" }]
      },
      selectRows: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.title = "公司设置";
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    async fetchData() {
      const { data } = await submit("/api/company/setting", {});
      this.form = { ...data };
      this.form.ExpireDate = dayjs(this.form.ExpireTime).format('YYYY-MM-DD')
    },
    save() {
      const _this = this;
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          let param = { ...this.form };
          await submit("/api/company/doSetting", param);
          this.$baseMessage("保存成功", "success");
          _this.fetchData();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-main {
    background-color: #e9edf1 !important;
  }

  .el-divider__text {
    font-size: 20px;
    font-weight: bold;
  }
}

.editForm {
  margin-top: 20px;
  margin-right: 150px;
}

.footer {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  .el-button {
    width: 120px;
  }
}
</style>
